.Message {
  align-items: center;
  flex-direction: column;

  > .MessageText {
    width: 100%;
    text-align: center;
  }

  > .FadeInOutButton {
    width: 5%;
  }
}

