.Dialog {
  align-items: flex-end;
}

.DialogFrame {
  aspect-ratio: 3.0352941176470587;
  width: 80%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  > .DialogText {
    width: 100%;
    height: 100%;
    position: absolute;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 3%;
  }

  > .FadeInOutButton {
    position: absolute;
    top: 75%;
    left: 88%;
    width: 5%;
  }
}

