.Level {
  width: 100%;
  height: 100%;
  position: relative;
}

.FloorBg {
  background-repeat: no-repeat;
  background-size: cover;
}

.Tile {
  pointer-events: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;

  > div {
    width: 56%;
    aspect-ratio: calc(1 / 0.8660);
    transform-origin: center;
    transform: rotate(-30deg) skew(30deg);
    justify-content: center;
    align-items: end;
    display: flex;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
}

.Step {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 60%;
    height: 60%;
    border: 2px solid #59249e;
    border-radius: 50%;
    z-index: 1;
  }
}