* {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
}

body {
  font-family: Verdana, monospace;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Canvas {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: black;
  justify-content: center;
}

.Layout {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  color: white;
  justify-content: center;
  z-index: 99;
  opacity: 0.9;
}

.Loading {
  align-items: center;
  opacity: 1;
}

.SpriteImage {
  position: absolute;
  z-index: 2;

  > div {
    position: absolute;
    background-repeat: no-repeat;
  }
}

.FixedImage {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
}

.FadeInOutButton {
  aspect-ratio: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: fadeInOut 1s infinite;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}