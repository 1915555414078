.Inventory {
  position: absolute;
  bottom: 1%;
  width: 64%;
  display: flex;
  z-index: 1;
  height: 8%;
  min-height: 48px;
  justify-content: space-between;
  pointer-events: none;
}

.Grid {
  display: flex;
  justify-content: center;

  &.Weapons {

    > div {
      border: 2px solid #59249e;
      background: rgb(86, 91, 152);
      background: radial-gradient(circle, rgba(86, 91, 152, 1) 0%, rgba(53, 56, 79, 1) 40%, rgba(0, 0, 0, 1) 100%);
    }
  }

  &.Items {

    > div {
      border: 2px solid #856714;
      background: rgb(97, 78, 54);
      background: radial-gradient(circle, rgba(97, 78, 54, 1) 0%, rgba(79, 71, 53, 1) 30%, rgba(0, 0, 0, 1) 100%);
    }
  }

  &.Buttons {

    > div {
      border: 2px solid #b7b3bd;
      background: rgb(117, 115, 107);
      background: radial-gradient(circle, rgba(117, 115, 107, 1) 0%, rgba(88, 84, 83, 1) 40%, rgba(0, 0, 0, 1) 100%);

    }
  }

  > div {
    margin: 0 2%;
    border-radius: 8px;
    height: 100%;
    aspect-ratio: 1;
    padding: 5%;

    > div {
      height: 100%;
      aspect-ratio: 1;
      background-repeat: no-repeat;
      background-size: cover;
      pointer-events: all;

      &.Empty {
        opacity: 0.2;
      }
    }

  }

}